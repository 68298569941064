import { newDateFromString } from '../utils/date.utils'

export class Interimaire {
    id: number
    nom: string
    prenom: string
    matricule: string
    ett: string
    carteBtp: string
    genreCode: string
    genreNom: string
    indicatifTelephone: string
    telephone: string
    email: string
    dateDeNaissance: Date
    dateFinMissionActuelle: Date
    dateFinMission: Date
    dateDemarrage: Date
    dateFinInitiale: Date
    dateExpirationTitreDeSejour: Date
    dateEcheanceDeLaVisiteMedicale: Date
    nationaliteCode: string
    nationaliteNom: string
    qualification: string
    emploi: string
    statut: string
    actif: boolean
    photoData: null
    statutContrat: string
    statutInterimaire: string
    dateSignature: Date
    tauxHoraire: string
    tauxHoraireFacture: string
    coefficient: string
    transport: string
    interne: boolean
    codeChantier: string
    nomChantier: string
    numeroContrat: string
    dateEcheanceCarteProBTP: Date
    constructor(init?: Partial<Interimaire>) {
        Object.assign(this, init)
        this.dateDeNaissance = newDateFromString(this.dateDeNaissance)
        this.dateFinMissionActuelle = newDateFromString(this.dateFinMissionActuelle)
        this.dateSignature = newDateFromString(this.dateSignature)
        this.dateFinMission = newDateFromString(this.dateFinMission)
        this.dateDemarrage = newDateFromString(this.dateDemarrage)
        this.dateFinInitiale = newDateFromString(this.dateFinInitiale)
        this.dateExpirationTitreDeSejour = newDateFromString(this.dateExpirationTitreDeSejour)
        this.dateEcheanceDeLaVisiteMedicale = newDateFromString(this.dateEcheanceDeLaVisiteMedicale)
        this.dateEcheanceCarteProBTP = newDateFromString(this.dateEcheanceCarteProBTP)
    }

    getDisplayName(): string {
        return (
            this.prenom.slice(0, 1).toUpperCase() +
            this.prenom.slice(1).toLowerCase() +
            ' ' +
            this.nom.toUpperCase() +
            ' (' +
            this.matricule +
            ')'
        )
    }

    getField(fieldName: keyof Interimaire): unknown {
        return this[fieldName]
    }
}
