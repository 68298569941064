import { Injectable } from '@angular/core'
import { Store } from '../../models/store'
import { User } from '../../models/user'
import { ChantierDTO } from '../http/dto/chantier-dto'

@Injectable({ providedIn: 'root' })
export class UserState {
    store = new Store<Partial<User>>(null)
    static getConnectedUser = (state: Partial<User>): User => {
        if (state?.id) {
            return new User(state)
        }
        return null
    }

    static getLastChantierCode = (state: Partial<User>): string => state.dernierChantierCode

    static getUserChantiers = (state: Partial<User>): ChantierDTO[] => state.chantiers

    static connectedUserIsSuperAdmin = (state: Partial<User>): boolean => state.superAdmin

    static getChantierNomByCode =
        (chantierCode: string) =>
        (state: Partial<User>): string =>
            state.chantiers.find((chantier) => chantier.code === chantierCode)?.nom
}
