import { ApplicationDto } from '../../data-access/http/dto/application.dto'
import { applicationSettings } from '../../data-access/constant/application-settings'
import { Application } from '../../../shared/models/application'

export class ApplicationMapper {
    static mapApplicationDtosToApplications = (applicationDtos: ApplicationDto[]): Application[] => {
        return applicationDtos
            .filter((appDto) => appDto.affiche)
            .map(
                (appDto: ApplicationDto): Application => ({
                    code: appDto.codeApplication,
                    name: appDto.nomApplication,
                    desc: appDto.libelleApplication,
                    ordreTri: appDto.ordreTri,
                    inactiveLink: appDto.urlModeInActif,
                    activeLink: appDto.urlModeActif,
                    activeLinkText: "Accéder à l'application",
                    logo: appDto.hasLogo ? `../assets/images/logos/${appDto.codeApplication}.svg` : null,
                    displayDescUnderTitle: true,
                    activeByDefault: false,
                    openLinksInNewTab: true,
                    doNotSendEvenement: false,
                    highlighted: false,
                    helpLink: appDto.helpLink,
                    isIris: appDto.iris,
                    isPortailChantier: appDto.portailCht,
                    isSectionIris: appDto.sectionIris,
                    disableLogoFilter: false,
                }),
            ) as Application[]
    }

    static applyInfosFromSettings = (applications: Application[]): Application[] =>
        applications
            .map((app) => {
                if (applicationSettings.has(app.code)) {
                    return {
                        ...app,
                        ...applicationSettings.get(app.code),
                    }
                }
                return app
            })
            .sort((a, b) => a.ordreTri - b.ordreTri)
}
