import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { CurrencyPipe, NgFor, NgIf } from '@angular/common'
import { ApplicationStatusEnum } from '../../../../shared/models/enums/application-status.enum'
import { Achats360Dto } from '../../../data-access/http/dto/achats360.dto'
import { Chart, ChartConfiguration, ChartOptions } from 'chart.js/auto'
import { getGenericChartOptions } from '../../../../shared/utils/utils'

@Component({
    selector: 'app-planning-livraison-portlet-body',
    templateUrl: './achats360-portlet-body.component.html',
    styleUrls: ['./achats360-portlet-body.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, CurrencyPipe],
})
export class Achats360PortletBodyComponent implements AfterViewInit {
    protected data: Achats360Dto
    protected readonly applicationStatusEnum = ApplicationStatusEnum
    @ViewChild('chartCanvas')
    chartCanvas: ElementRef
    chart: Chart
    constructor(
        @Inject('DATA') private dataInjected: string,
        @Inject('STATUS') protected status: ApplicationStatusEnum,
    ) {
        this.data = JSON.parse(this.dataInjected)
    }

    ngAfterViewInit(): void {
        if (this.data?.codeChantier) {
            this.chart = this.buildChart(this.chartCanvas)
        }
    }

    private buildChart(chartCanvasElement: ElementRef): Chart {
        const chartData = {
            labels: this.data.moisDepenses.map(
                (moisDepense) => moisDepense.moisAnnee.slice(4, 6) + '/' + moisDepense.moisAnnee.slice(0, 4),
            ),
            datasets: [
                {
                    label: 'Dépenses',
                    data: this.data.moisDepenses.map((moisDepense) => moisDepense.depensesMois),
                    hoverOffset: 4,
                },
            ],
        }

        const chartOptions: ChartOptions = {
            ...getGenericChartOptions(),
            scales: {
                ...getGenericChartOptions().scales,
            },
        }

        const chartConfig: ChartConfiguration = { type: 'bar', data: chartData, options: chartOptions }
        return new Chart(chartCanvasElement.nativeElement.getContext('2d'), chartConfig)
    }
}
