import { Component, inject, Inject } from '@angular/core'
import { DocumentGedDto } from '../../../../shared/data-access/http/dto/document-ged.dto'
import { DatePipe, NgForOf, NgIf } from '@angular/common'
import { DocumentGed } from '../../../../shared/models/document-ged'
import { parseCustomDate } from '../../../../shared/utils/date.utils'
import { DocumentationDomainService } from '../../../../shared/domain/documentation.domain'

@Component({
    selector: 'app-documentation-chantier-portlet-body',
    standalone: true,
    imports: [NgForOf, DatePipe, NgIf],
    templateUrl: './documentation-chantier-portlet-body.component.html',
    styleUrl: './documentation-chantier-portlet-body.component.scss',
})
export class DocumentationChantierPortletBodyComponent {
    data: DocumentGed[]
    private readonly dtos: DocumentGedDto[]
    private readonly documentationDomainService = inject(DocumentationDomainService)

    constructor(@Inject('DATA') private dataInjected: string) {
        this.dtos = JSON.parse(this.dataInjected)
        this.data = this.dtos.map((dto) => this.mapDocumentDtoToDocument(dto))
    }

    openDocument(documentId: string): void {
        this.documentationDomainService
            .openDocument(this.dtos.find((dto) => dto.document_id === documentId))
            .subscribe()
    }

    private mapDocumentDtoToDocument(dto: DocumentGedDto): DocumentGed {
        /* eslint-disable @typescript-eslint/naming-convention */

        return {
            certificatRealisation_fileName: dto.certificatRealisation_fileName,
            certificatRealisation_id: dto.certificatRealisation_id,
            chantier_code: dto.chantier_code,
            chantier_nom: dto.chantier_nom,
            certificatRealisation_lambdaId: dto.certificatRealisation_lambdaId,
            createdBy_nom: dto.createdBy_nom,
            createdBy_prenom: dto.createdBy_prenom,
            nomDocument: dto.nomDocument,
            document_id: dto.document_id,
            document_lambdaId: dto.document_lambdaId,
            documentSigne_fileName: dto.documentSigne_fileName,
            documentSigne_id: dto.documentSigne_id,
            documentSigne_lambdaId: dto.documentSigne_lambdaId,
            enveloppeId: dto.enveloppeId,
            typeDocumentGed_nom: dto.typeDocumentGed_nom,
            createdDate: parseCustomDate(dto.createdDate),
            dateSignature: parseCustomDate(dto.dateSignature),
            collaborateur: dto.collaborateur,
            contributeur: dto.contributeur,
        }
        /* eslint-enable */
    }
}
