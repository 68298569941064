import { inject, Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { ChantierSavePhotoDto } from './dto/chantier-save-photo-dto'
import { ChantierDTO } from './dto/chantier-dto'
import { PageResponse } from './dto/page-dto'

import { ApplicationInfoDto } from '../../../home/data-access/http/dto/application-info.dto'
import { ApplicationStatusEnum } from '../../models/enums/application-status.enum'
import { GetEcartsInterimairesPointageCompagnonsDto } from './dto/get-ecarts-interimaires-pointage-compagnons.dto'

@Injectable({
    providedIn: 'root',
})
export class ChantierHttpService {
    private readonly http = inject(HttpClient)

    getChantierAdminEmails(codeChantier: string, size: number): Observable<string[]> {
        return this.http.get<string[]>(`${environment.baseUrl}/chantiers/${codeChantier}/admins?size=${size}`)
    }

    setChantierPhoto(chantierSavePhotoDto: ChantierSavePhotoDto): Observable<ChantierDTO> {
        return this.http.post<ChantierDTO>(`${environment.baseUrl}/chantiers`, chantierSavePhotoDto)
    }

    deleteChantierPhoto(codeChantier: string): Observable<string> {
        return this.http.request<string>('delete', `${environment.baseUrl}/chantiers/delete/${codeChantier}`, {
            responseType: 'text' as 'json',
        })
    }

    findChantiers(keywords: string, pageNum: number = 0): Observable<PageResponse<ChantierDTO>> {
        return this.http.get<PageResponse<ChantierDTO>>(
            `${environment.baseUrl}/chantiers/find?keyword=${keywords}&page=${pageNum}&size=20`,
        )
    }
    isChantierParametre(codeChantier: string): Observable<boolean> {
        return this.http
            .get<ApplicationInfoDto>(`${environment.baseUrl}/application/infos/POINTAGE_COMPAGNONS/${codeChantier}`)
            .pipe(map((resp) => resp?.actif && resp?.statut === ApplicationStatusEnum.ok))
    }

    getChantierWithPhoto(codeChantier: string): Observable<ChantierDTO> {
        return this.http.get<ChantierDTO>(environment.baseUrl + '/chantiers/downloadPhotoChantier/' + codeChantier)
    }

    getEcartsInterimairesPointageCompagnons(
        codeChantier: string,
    ): Observable<GetEcartsInterimairesPointageCompagnonsDto> {
        return this.http.get<GetEcartsInterimairesPointageCompagnonsDto>(
            environment.baseUrl + '/chantiers/ecartsInterimaires/' + codeChantier,
        )
    }
}
