import { PointageCompagnonPortletBodyComponent } from '../../ui/portlet-body/pointage-compagnon-portlet-body/pointage-compagnon-portlet-body.component'
import { BetonLeonPortletBodyComponent } from '../../ui/portlet-body/beton-leon-portlet-body/beton-leon-portlet-body.component'
import { LocmatPortletBodyComponent } from '../../ui/portlet-body/locmat-portlet-body/locmat-portlet-body.component'
import { DonnesInterimairesPortletBodyComponent } from '../../ui/portlet-body/donnes-interimaires-portlet-body/donnes-interimaires-portlet-body.component'
import { ApplicationCodeEnum } from '../../../shared/models/enums/application-code-enum'
import { Application } from '../../../shared/models/application'
import { PlanningLivraisonPortletBodyComponent } from '../../ui/portlet-body/planning-livraison-portlet-body/planning-livraison-portlet-body.component'
import { At360EntitesPortletBodyComponent } from '../../ui/portlet-body/at360-entites-portlet-body/at360-entites-portlet-body.component'
import { ActionsBetonLeonPortletBodyComponent } from '../../ui/portlet-body/actions-beton-leon-portlet-body/actions-beton-leon-portlet-body.component'
import { Achats360PortletBodyComponent } from '../../ui/portlet-body/achats-360-portlet-body/achats360-portlet-body.component'
import { At360ChantierPortletBodyComponent } from '../../ui/portlet-body/at360-chantier-portlet-body/at360-chantier-portlet-body.component'
import { KairnialPortletBodyComponent } from '../../ui/portlet-body/kairnial-portlet-body/kairnial-portlet-body.component'
import { Pointage360PortletBodyComponent } from '../../ui/portlet-body/pointage360-portlet-body/pointage360-portlet-body.component'
import { AuditsLogementsPortletBodyComponent } from '../../ui/portlet-body/audits-logements-portlet-body/audits-logements-portlet-body.component'
import { RendezVousLocatairesPortletBodyComponent } from '../../ui/portlet-body/rendez-vous-locataires-portlet-body/rendez-vous-locataires-portlet-body.component'
import { DocumentationChantierPortletBodyComponent } from '../../ui/portlet-body/documentation-chantier-portlet-body/documentation-chantier-portlet-body.component'
import { AjoutLocatairesContactsPortletBodyComponent } from '../../ui/portlet-body/ajout-locataires-contacts-portlet-body/ajout-locataires-contacts-portlet-body.component'

export const applicationSettings: Map<ApplicationCodeEnum, Partial<Application>> = new Map([
    [
        ApplicationCodeEnum.pointageCompagnons,
        {
            bodyComponent: PointageCompagnonPortletBodyComponent,
            displayDescUnderTitle: false,
            activeByDefault: false,
        },
    ],
    [
        ApplicationCodeEnum.betonLeon,
        {
            bodyComponent: BetonLeonPortletBodyComponent,
            displayDescUnderTitle: false,
            activeByDefault: false,
        },
    ],
    [
        ApplicationCodeEnum.locmat,
        {
            bodyComponent: LocmatPortletBodyComponent,
            displayDescUnderTitle: false,
            activeByDefault: false,
        },
    ],
    [
        ApplicationCodeEnum.donneesInterimaires,
        {
            bodyComponent: DonnesInterimairesPortletBodyComponent,
            displayDescUnderTitle: false,
            activeByDefault: false,
        },
    ],
    [
        ApplicationCodeEnum.planningLivraisonChantier,
        {
            bodyComponent: PlanningLivraisonPortletBodyComponent,
            displayDescUnderTitle: false,
            activeByDefault: false,
        },
    ],
    [ApplicationCodeEnum.kairnial, { activeByDefault: true, bodyComponent: KairnialPortletBodyComponent }],
    [
        ApplicationCodeEnum.at360entites,
        {
            displayDescUnderTitle: false,
            activeByDefault: false,
            bodyComponent: At360EntitesPortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.at360chantier,
        {
            displayDescUnderTitle: false,
            activeByDefault: false,
            bodyComponent: At360ChantierPortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.actionsBetonLeon,
        {
            displayDescUnderTitle: false,
            activeByDefault: false,
            bodyComponent: ActionsBetonLeonPortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.achats360,
        {
            displayDescUnderTitle: false,
            activeByDefault: false,
            bodyComponent: Achats360PortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.pointageChantier360,
        {
            displayDescUnderTitle: true,
            activeByDefault: true,
            bodyComponent: Pointage360PortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.auditsLogements,
        {
            disableLogoFilter: true,
            bodyComponent: AuditsLogementsPortletBodyComponent,
            activeLinkText: "Accéder à l'application sur PC",
        },
    ],
    [
        ApplicationCodeEnum.rendezVousLocataires,
        {
            disableLogoFilter: true,
            bodyComponent: RendezVousLocatairesPortletBodyComponent,
            activeLinkText: "Accéder à l'application sur PC",
        },
    ],
    [
        ApplicationCodeEnum.ajoutLocatairesContacts,
        {
            activeByDefault: true,
            displayDescUnderTitle: false,
            bodyComponent: AjoutLocatairesContactsPortletBodyComponent,
        },
    ],
    [
        ApplicationCodeEnum.documentationChantier,
        {
            displayDescUnderTitle: false,
            activeLinkText: 'Voir tous les documents',
            code: ApplicationCodeEnum.documentationChantier,
            doNotSendEvenement: true,
            bodyComponent: DocumentationChantierPortletBodyComponent,
            activeLink: '/documentation',
            openLinksInNewTab: false,
        },
    ],
])
