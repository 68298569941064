import { inject, Injectable } from '@angular/core'
import { combineLatestWith, Observable, of, switchMap, tap } from 'rxjs'
import { PieceJointeHttpService } from '../data-access/http/piece-jointe-http.service'
import { PieceJointeDto } from '../data-access/http/dto/piece-jointe.dto'
import { downloadFile } from '../utils/utils'

@Injectable({ providedIn: 'root' })
export class PieceJointeDomainService {
    private readonly pieceJointeHttpService = inject(PieceJointeHttpService)

    downloadPieceJointeById(documentId: string): Observable<[Blob, PieceJointeDto]> {
        return this.pieceJointeHttpService.getPhotoById(documentId).pipe(
            switchMap((pieceJointe: PieceJointeDto) =>
                this.pieceJointeHttpService
                    .downloadPieceJointe(pieceJointe.lambdaUrl)
                    .pipe(combineLatestWith(of(pieceJointe))),
            ),
            tap(([blob, pieceJointe]) => downloadFile(blob, pieceJointe.fileName)),
        )
    }
}
