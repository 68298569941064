import { DeviceEvenementDto } from '../data-access/http/dto/device-evenement.dto'
import { inject, InjectionToken } from '@angular/core'
import { DeviceDetectorService } from 'ngx-device-detector'

export function getDeviceInfo(): DeviceEvenementDto {
    const deviceDetectorService = inject(DeviceDetectorService)
    return {
        type: deviceDetectorService.deviceType,
        nom: deviceDetectorService.device,
        nomOs: `${deviceDetectorService.os} ${deviceDetectorService.os_version}`,
        nomNavigateur: `${deviceDetectorService.browser} ${deviceDetectorService.browser_version}`,
    }
}

export const DEVICE_INFO = new InjectionToken<DeviceEvenementDto>("Infos sur l'appareil de l'utilisateur")
