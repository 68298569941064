import { inject, Injectable } from '@angular/core'
import { CoreState, CoreStateModel } from '../data-access/state/core.state'
import { UserState } from '../../shared/data-access/state/user.state'
import { combineLatestWith, distinctUntilChanged, filter, map, mergeWith, Observable, of, switchMap, tap } from 'rxjs'
import { ChantierDTO } from '../../shared/data-access/http/dto/chantier-dto'
import { PageResponse } from '../../shared/data-access/http/dto/page-dto'
import { ParamState } from '../../shared/data-access/state/param.state'
import { ParamCodeEnum } from '../../shared/models/enums/param-code.enum'
import { NavigationEnd, Router } from '@angular/router'
import { ChantierHttpService } from '../../shared/data-access/http/chantier-http.service'
import { BreadcrumbDatas } from '../ui/header/header.component'
import { logoSettings } from '../data-access/constant/logo-settings.constant'
import { PortailEnum } from '../../shared/models/enums/portail.enum'
import { LogoSetting } from '../../shared/models/logo-setting'
import { ChantierState } from '../../shared/data-access/state/chantier.state'

@Injectable({ providedIn: 'root' })
export class CoreDomainService {
    private readonly chantierHttpService = inject(ChantierHttpService)
    private readonly userState = inject(UserState)
    private readonly paramState = inject(ParamState)
    private readonly coreState = inject(CoreState)
    private readonly router = inject(Router)
    private readonly chantierState = inject(ChantierState)

    // Selectors
    searchedChantiers$ = this.coreState.store.select(CoreState.getSearchedChantiers)
    emailsAdminChantier$ = this.coreState.store.select(CoreState.getChantierAdminEmails)
    chantierIsParametre$ = this.coreState.store.select(CoreState.getIsChantierParametre)
    selectedChantier$ = this.coreState.store.select(CoreState.getSelectedChantier)
    supportEmail$ = this.coreState.store.select(CoreState.getSelectedChantier).pipe(
        combineLatestWith(this.paramState.store.select(ParamState.getParams)),
        map(([selectedChantierInModal, params]) => {
            const paramCode: ParamCodeEnum = selectedChantierInModal?.iris
                ? ParamCodeEnum.footerContactMailIris
                : ParamCodeEnum.footerContactMail
            return params?.find((param) => param.codeParam === paramCode)?.valeurParam
        }),
    )
    shouldDisplayBreadcrumbInHeader$: Observable<boolean> = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        mergeWith(
            of({
                url: this.router.url,
            }),
        ),
        map((navigEvent) => {
            const path = (navigEvent as NavigationEnd).url.split('?')[0]

            const isAdminPage = path === '/admin'
            const isDroitsRHPage = path === '/droitsrh'
            const isAccueilChantierPage = path === '/accueil-chantier'
            const isAccueilSecuritePage = path === '/accueil-chantier/securite'
            const isAdminImportBetonPage = path === '/admin-import-beton'
            const isAutorisationConduitePage = path === '/accueil-chantier/autorisation-conduite'
            const isDocumentationPage = path === '/documentation'
            return (
                isAdminPage ||
                isDroitsRHPage ||
                isAccueilChantierPage ||
                isAccueilSecuritePage ||
                isAdminImportBetonPage ||
                isAutorisationConduitePage ||
                isDocumentationPage
            )
        }),
        distinctUntilChanged(),
    )
    breadcrumbDatas$: Observable<BreadcrumbDatas> = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        mergeWith(
            of({
                url: this.router.url,
            }),
        ),
        map((navigEvent) => {
            const path = (navigEvent as NavigationEnd).url.split('?')[0]

            const isAdminPage = path === '/admin'
            const isDroitsRHPage = path === '/droitsrh'
            const isAccueilChantierPage = path === '/accueil-chantier'
            const isAccueilSecuritePage = path === '/accueil-chantier/securite'
            const isAdminImportBetonPage = path === '/admin-import-beton'
            const isAutorisationConduitePage = path === '/accueil-chantier/autorisation-conduite'
            const isDocumentationPage = path === '/documentation'

            return {
                paths: [
                    { isActive: isAdminPage, path: [{ label: 'Administration' }] },
                    { isActive: isDroitsRHPage, path: [{ label: 'Données RH Collaborateurs' }] },
                    { isActive: isAccueilChantierPage, path: [{ label: 'Accueil chantier' }] },
                    {
                        isActive: isAccueilSecuritePage,
                        path: [
                            {
                                label: 'Accueil chantier',
                                lien: '/accueil-chantier',
                            },
                            { label: 'Accueil sécurité sur chantier' },
                        ],
                    },
                    {
                        isActive: isAdminImportBetonPage,
                        path: [
                            {
                                label: 'Administration',
                                lien: '/admin',
                            },
                            { label: 'Administration Import Béton' },
                        ],
                    },
                    {
                        isActive: isAutorisationConduitePage,
                        path: [
                            {
                                label: 'Accueil chantier',
                                lien: '/accueil-chantier',
                            },
                            { label: 'Autorisation de conduite' },
                        ],
                    },
                    { isActive: isDocumentationPage, path: [{ label: 'Documentation chantier' }] },
                ],
            }
        }),
    )
    shouldDisplayAccesChantierButton$: Observable<boolean> = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).url),
        mergeWith(of(this.router.url)),
        map((currentUrl: string) => {
            const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
            const isNotFoundPage = currentUrl.startsWith('/not-found')
            const isHomePage = currentUrl.startsWith('/chantiers')
            const isNoChantierPage = currentUrl.startsWith('/no-chantier')
            return isNotFoundPage || isNoChantierPage || (isHomePage && connectedUser && !connectedUser.chantiers)
        }),
    )

    currentLogo$: Observable<LogoSetting> = this.chantierState.store.select(ChantierState.getSelectedChantier).pipe(
        map((selectedChantier) => {
            if (!selectedChantier) {
                return logoSettings.get(PortailEnum.portailChantier)
            }
            if (selectedChantier.iris) {
                return logoSettings.get(PortailEnum.portailIris)
            }
            return logoSettings.get(PortailEnum.portailChantier)
        }),
    )

    getSelectedChantier(): ChantierDTO {
        return this.coreState.store.selectSnapshot(CoreState.getSelectedChantier)
    }

    // Actions

    addCurrentChantierToUserChantiers(): void {
        const user = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const selectedChantier = this.coreState.store.selectSnapshot(CoreState.getSelectedChantier)
        if (user.chantiers.map((c) => c.code).includes(selectedChantier.code)) {
            return
        }
        const newUser = {
            ...user,
            chantiers: [...user.chantiers, this.coreState.store.selectSnapshot(CoreState.getSelectedChantier)],
        }
        this.userState.store.update(() => newUser)
    }

    findChantiers(keywords: string, page: number): Observable<PageResponse<ChantierDTO>> {
        return this.chantierHttpService
            .findChantiers(keywords, page)
            .pipe(
                tap((chantiers) =>
                    this.coreState.store.update((state) => ({ ...state, searchedChantiers: chantiers.content })),
                ),
            )
    }

    findNewChantiersPage(keywords: string, pageNum: number): Observable<PageResponse<ChantierDTO>> {
        return this.chantierHttpService.findChantiers(keywords, pageNum).pipe(
            tap((chantiers) =>
                this.coreState.store.update((state: CoreStateModel) => ({
                    ...state,
                    searchedChantiers: [...state.searchedChantiers, ...chantiers.content].sort((a, b) =>
                        a.code.localeCompare(b.code),
                    ),
                })),
            ),
        )
    }

    selectChantierInModal(chantierCode: string): Observable<string[]> {
        const searchedChantiers = this.coreState.store.selectSnapshot(CoreState.getSearchedChantiers)
        const selectedChantier = searchedChantiers.find((chantier) => chantier.code === chantierCode)
        this.coreState.store.update((state) => ({
            ...state,
            selectedChantier,
        }))
        return this.checkParametrageChantier(chantierCode).pipe(
            switchMap((chantierIsParametre) => {
                if (chantierIsParametre) {
                    return this.findChantierAdminsEmails(chantierCode, 4)
                }
                return of(null)
            }),
        )
    }

    resetState(): void {
        this.coreState.store.update(() => ({
            selectedChantier: null,
            chantierAdminEmails: [],
            isChantierParametre: false,
            searchedChantiers: [],
        }))
    }

    private checkParametrageChantier(codeChantier: string): Observable<boolean> {
        return this.chantierHttpService.isChantierParametre(codeChantier).pipe(
            tap((isChantierParametre) =>
                this.coreState.store.update((state: CoreStateModel) => ({
                    ...state,
                    isChantierParametre,
                })),
            ),
        )
    }

    private findChantierAdminsEmails(codeChantier: string, size: number): Observable<string[]> {
        return this.chantierHttpService.getChantierAdminEmails(codeChantier, size).pipe(
            tap((chantierAdminEmails) =>
                this.coreState.store.update((state: CoreStateModel) => ({
                    ...state,
                    chantierAdminEmails,
                })),
            ),
        )
    }
}
