import { inject, Injectable } from '@angular/core'
import { AuthHttpService } from '../data-access/http/auth-http.service'
import { UserState } from '../data-access/state/user.state'
import { User } from '../models/user'
import { combineLatestWith, map, Observable, tap } from 'rxjs'
import { ChantierState } from '../data-access/state/chantier.state'
import { UserHttpService } from '../data-access/http/user-http.service'
import { DEVICE_INFO } from '../factories/device-info.factory'

@Injectable({ providedIn: 'root' })
export class UserDomainService {
    private readonly authHttpService = inject(AuthHttpService)
    private readonly userHttpService = inject(UserHttpService)
    private readonly userState = inject(UserState)
    private readonly chantierState = inject(ChantierState)
    private readonly deviceInfo = inject(DEVICE_INFO)

    // Selectors
    userChantiers$ = this.userState.store.select(UserState.getUserChantiers)

    userIsSuperAdminOrAdminChantier$: Observable<boolean> = this.userState.store
        .select(UserState.connectedUserIsSuperAdmin)
        .pipe(
            combineLatestWith(this.chantierState.store.select(ChantierState.getSelectedChantier)),
            map(([userIsSuperAdmin, chantier]) => userIsSuperAdmin || chantier.admin),
        )

    connectedUser(): User {
        return this.userState.store.selectSnapshot(UserState.getConnectedUser)
    }

    userIsSuperAdminOrAdminChantier(): boolean {
        const superAdmin = this.userState.store.selectSnapshot(UserState.connectedUserIsSuperAdmin)
        const selectedChantier = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantier)
        return superAdmin || selectedChantier.admin
    }

    userIsSuperAdmin(): boolean {
        return this.userState.store.selectSnapshot(UserState.connectedUserIsSuperAdmin)
    }

    // Actions
    logOut(): void {
        this.authHttpService.logOut()
        this.userState.store.update(() => null)
    }

    loadConnectedUser(): Observable<User> {
        return this.userHttpService.getConnectedUser(this.deviceInfo).pipe(
            tap((user) => {
                this.userState.store.update(() => {
                    const { ...userProperties } = user
                    return userProperties
                })
            }),
        )
    }

    setDernierChantierCode(dernierChantierCode: string): Observable<User> {
        return this.userHttpService.majUserDernierChantier(dernierChantierCode).pipe(
            tap(() => {
                this.userState.store.update((user) => ({ ...user, dernierChantierCode }))
            }),
        )
    }
}
