import { Environment } from '../app/shared/models/environment'

export const environment: Environment = {
    production: true,
    name: 'dev',
    baseUrl: 'https://portailchantier-dev.leon-grosse.fr/portailchantier-api-rest',
    pointageUrl: 'https://backoffice-lg-compagnons-qa.apnl.info/',
    pointage360Url:
        'https://reporting.leon-grosse.fr/sense/app/3e120da9-ce03-4ddb-a525-3d29c1e66729/sheet/27800aa2-ddf9-4106-aa65-55355bfe0012/state/analysis',
    listePostesSecuriteUrl: 'https://leonlive.leongrosse.fr/home/formation-renforcee',
    awsLambdaParam: 'devportailcht',
    clientId: '265792391993-k8jo3r13pp63monmk9pjsi1p1bo1b9ud.apps.googleusercontent.com',
    appId: '265792391993',
    oauth: {
        clientId: '54bk0b14jr39a7kdgjcpfus6ev',
        loginUrl: 'https://lguserpooldev.auth.eu-west-3.amazoncognito.com/oauth2/authorize',
        issuer: 'https://cognito-idp.eu-west-3.amazonaws.com/eu-west-3_bt23vOhrX',
        logoutUrl: 'https://lguserpooldev.auth.eu-west-3.amazoncognito.com/logout',
        tokenEndpoint: 'https://lguserpooldev.auth.eu-west-3.amazoncognito.com/oauth2/token',
        userInfoEndpoint: 'https://lguserpooldev.auth.eu-west-3.amazoncognito.com/oauth2/userInfo',
        jwks: {
            keys: [
                {
                    alg: 'RS256',
                    e: 'AQAB',
                    kid: 'Ct4WmgsLXN8U9BahXbjrPLJ2prb/t1cjf3JfyfQOU9E=',
                    kty: 'RSA',
                    n: 'uLO2QX5R0T1cU5M5vfEdStPwrXhja1rUqRZh1xgkgYyx7V4uOKeNyeOAtld-FaQPFaHwB1ffqGWwZqMBOKT5w24blDipzz4zG5tCa-ZTJvl867ADbsZjvnJzDJwO8KIvjShYyoxfaftxd1e3ZI1V1wJqPOYi4Vavo55QbY4jm5PvsyCVjYLd9oqXsHjz62k8Yl8nL9OeH65zXaYPZ2J2u774JnRSJ-St9qb11F0oi54F6XqHhKg0HiQ-f6L8m8j9KZyz3b4qmQjH1Z-vvO9DCEkoVVcRq7BpPjKl44e03Au2G4JhKEisq0BCVqi_cOJ-DsK_9KQj2tHokVbH3BgvOw',
                    use: 'sig',
                },
                {
                    alg: 'RS256',
                    e: 'AQAB',
                    kid: 'OcpNeH9tIB9v/yUeEgJyFmLGze+YRAL9FrXX9WbMmAs=',
                    kty: 'RSA',
                    n: 'wtjSbS8ICLFyktrnkxY0e-jWyDINYmsLaXr8sk9R0bQEN82469fje3Gc3MinqVJopgVJhns2JzIrUO16GDa4wIGLipKKy1Mnx0j2MDiYF1iEudKARHOHyDuLhYpn10k3lNBVC26WjVypA3ncPcdGkTXG6G8K7j9IABudKjTZUkoLxjSJlh5l7yvKvFwGLl25p4darjf4oV2qZlic5eZ2gzUryTRoZSRj8bDP_eEa7pBMRJ-yjAkBV_H3um76hXtkkIMX4aa0TQAdhx-lji36_97wpzqHQxvX0J3wCXcmNo8UNs0T1hyydOjrKU-aLygAM9ZASEJkfO0Qd2lcOJyqAw',
                    use: 'sig',
                },
            ],
        },
    },
}
