<div class="container py-0 px-0 h-100">
    <div class="row">
        <form (ngSubmit)="onSearchByCarteBTPFormSubmit()" [formGroup]="searchByCarteBTPForm">
            <div class="row">
                <div class="col-10 d-flex flex-column">
                    <div class="row">
                        <app-switch-input
                            leftLabel="Salarié Léon Grosse"
                            rightLabel="Intérimaire"
                            formControlName="collabSwitch"
                        />
                    </div>
                    <label class="form-label" for="carteBTPNumber">&nbsp;N° Carte BTP</label>
                    <div class="row">
                        <div class="col-8">
                            <input
                                (input)="onCarteBTPInputChange()"
                                (keydown)="validateKey($event)"
                                aria-describedby="carteBTPNumberHelp"
                                class="form-control"
                                formControlName="carteBTPNumber"
                                id="carteBTPNumber"
                                placeholder="012 345 678 9"
                                type="text"
                            />
                        </div>
                        <div class="col-4">
                            <div class="col-2 d-flex mb-3 align-items-end">
                                <button
                                    [disabled]="searchByCarteBTPForm.invalid"
                                    class="btn btn-primary"
                                    style="min-width: 10rem"
                                    type="submit"
                                >
                                    <span>Rechercher</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
