import { inject, Injectable } from '@angular/core'
import { CodeTypeEvenement, EvenementDto } from '../data-access/http/dto/evenement-dto'
import { UserState } from '../data-access/state/user.state'
import { EvenementHttpService } from '../data-access/http/evenement-http.service'
import { Observable } from 'rxjs'

import { Application } from '../models/application'
import { Salarie } from '../models/salarie'
import { Interimaire } from '../models/interimaire'
import { ChantierState } from '../data-access/state/chantier.state'
import { ApplicationCodeEnum } from '../models/enums/application-code-enum'

@Injectable({ providedIn: 'root' })
export class EvenementDomainService {
    private userState = inject(UserState)
    private chantierState = inject(ChantierState)
    private evenementService = inject(EvenementHttpService)

    // Actions
    createSelectionChantierEvenement(codeChantier: string): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeTypeEvenement('SELECTION_CHANTIER')
            .codeChantier(codeChantier)
            .commentaire(`L'utilisateur ${connectedUser.idUtilisateurSi} a accédé au chantier ${codeChantier}`)

        return this.evenementService.createEvenement(event.build())
    }

    createAccesDroitsRhEvenement(): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeTypeEvenement('ACCES_RECHERCHE_RH')
            .commentaire(`L'utilisateur ${connectedUser.idUtilisateurSi} a accédé à la page de recherche RH`)

        return this.evenementService.createEvenement(event.build())
    }

    createSelectionPortletEvenement(codeChantier: string, application: Application): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeTypeEvenement('SELECTION_PORTLET')
            .codeApplication(application.code)
            .commentaire(`L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur le portlet ${application.name}`)
            .codeChantier(codeChantier)

        return this.evenementService.createEvenement(event.build())
    }

    createSelectionPortletInactifEvenement(codeChantier: string, application: Application): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeTypeEvenement('SELECTION_PORTLET_INACTIF')
            .codeChantier(codeChantier)
            .codeApplication(application.code)
            .commentaire(
                `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur le portlet ${application.name} depuis la section Solutions non activées sur le chantier ${codeChantier}`,
            )

        return this.evenementService.createEvenement(event.build())
    }

    createAccesAccueilSecuriteEvenement(collab: Salarie | Interimaire): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const salarieCodeRh = (collab as Salarie).codeRh
        const interimaireMatricule = (collab as Interimaire).matricule
        const event = new EventBuilder().utilisateurId(connectedUser.id).codeTypeEvenement('CLIQUE_BTN_ACCUEIL_SECU')
        if (salarieCodeRh) {
            event
                .codeRhSalarie(salarieCodeRh)
                .commentaire(
                    `L'utilisateur ${connectedUser.idUtilisateurSi} a accédé à la page d'accueil à la sécurité sur chantier depuis la page Accueil sur Chantier pour le salarié ${salarieCodeRh}`,
                )
        }
        if (interimaireMatricule) {
            event
                .matriculeInterimaire(interimaireMatricule)
                .commentaire(
                    `L'utilisateur ${connectedUser.idUtilisateurSi} a accédé à la page d'accueil à la sécurité sur chantier depuis la page Accueil sur Chantier pour l'intérimaire ${interimaireMatricule}`,
                )
        }
        return this.evenementService.createEvenement(event.build())
    }

    createGenerationPdfSecuriteEvenement(
        salarieCodeRh: string,
        interimaireMatricule: string,
    ): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const event = new EventBuilder().utilisateurId(connectedUser.id).codeTypeEvenement('CLIQUE_GEN_REGISTRE_SECU')

        if (salarieCodeRh) {
            event
                .codeRhSalarie(salarieCodeRh)
                .commentaire(
                    `L'utilisateur ${connectedUser.idUtilisateurSi} a généré un registre d'accueil sécurité pour le salarié ${salarieCodeRh}`,
                )
        }
        if (interimaireMatricule) {
            event
                .matriculeInterimaire(interimaireMatricule)
                .commentaire(
                    `L'utilisateur ${connectedUser.idUtilisateurSi} a généré un registre d'accueil sécurité pour l'intérimaire ${interimaireMatricule}`,
                )
        }

        return this.evenementService.createEvenement(event.build())
    }

    createAccueilPcoEvenement(collab: Salarie | Interimaire): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const salarieCodeRh = (collab as Salarie).codeRh
        const interimaireMatricule = (collab as Interimaire).matricule
        const event = new EventBuilder().utilisateurId(connectedUser.id).codeTypeEvenement('CLIQUE_BTN_ACCUEIL_PCO')
        if (salarieCodeRh) {
            event
                .codeRhSalarie(salarieCodeRh)
                .commentaire(
                    `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur le bouton "Accueillir dans Pointage Compagnons" depuis la page Accueil sur Chantier pour le salarié ${salarieCodeRh}`,
                )
        }
        if (interimaireMatricule) {
            event
                .matriculeInterimaire(interimaireMatricule)
                .commentaire(
                    `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur le bouton "Accueillir dans Pointage Compagnons" depuis la page Accueil sur Chantier pour l’intérimaire ${interimaireMatricule}`,
                )
        }

        return this.evenementService.createEvenement(event.build())
    }

    createAccueilChantierPortletEvenement(): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const selectedChantierCode = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeTypeEvenement('ACCES_ACCUEIL_CHANTIER_PORTLET')
            .codeChantier(selectedChantierCode)
            .commentaire(
                `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur le bouton Accéder à la page d’accueil depuis le Portlet Accueil Chantier dans la section Outils`,
            )

        return this.evenementService.createEvenement(event.build())
    }

    createClickVideoSecuEvenement(): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeTypeEvenement('CLIQUE_BTN_VIDEO_ACCUEIL_SECU')
            .commentaire(
                `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur le bouton Vidéo d'accueil sécurité depuis la section Règles de sécurité et bonnes pratiques de la page Registre d’accueil à la sécurité`,
            )
        return this.evenementService.createEvenement(event.build())
    }

    createAccesAccueilChantierDepuisDonnesRh(collab: Salarie | Interimaire): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const salarieCodeRh = (collab as Salarie).codeRh
        const interimaireMatricule = (collab as Interimaire).matricule

        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeTypeEvenement('ACCES_ACCUEIL_CHANTIER_PAGE_RH')

        if (salarieCodeRh) {
            event
                .codeRhSalarie(salarieCodeRh)
                .commentaire(
                    `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur le bouton "Accueillir sur chantier" depuis la page des données RH après avoir fait une recherche sur le salarié ${salarieCodeRh}`,
                )
        }
        if (interimaireMatricule) {
            event
                .matriculeInterimaire(interimaireMatricule)
                .commentaire(
                    `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur le bouton "Accueillir sur chantier" depuis la page des données RH après avoir fait une recherche sur l’intérimaire ${interimaireMatricule}`,
                )
        }

        return this.evenementService.createEvenement(event.build())
    }

    createClickOpenDocumentFromDonneesRhEvenement(collab: Salarie | Interimaire): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const salarieCodeRh = (collab as Salarie).codeRh
        const interimaireMatricule = (collab as Interimaire).matricule

        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeTypeEvenement('CLIQUE_OPEN_COLLAB_DOCUMENT')

        if (salarieCodeRh) {
            event
                .codeRhSalarie(salarieCodeRh)
                .commentaire(
                    `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur un document pour le télécharger depuis la page des données RH après avoir fait une recherche sur le salarié ${salarieCodeRh}`,
                )
        }
        if (interimaireMatricule) {
            event
                .matriculeInterimaire(interimaireMatricule)
                .commentaire(
                    `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur un document pour le télécharger depuis la page des données RH après avoir fait une recherche sur l'intérimaire ${interimaireMatricule}`,
                )
        }
        return this.evenementService.createEvenement(event.build())
    }

    createClickAccesDocumentationEvenement(): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeTypeEvenement('CLIQUE_PORTLET_DOCUMENTATION')
            .commentaire(
                `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur "Accéder à la documentation" dans la portlet Documentation Chantier`,
            )

        return this.evenementService.createEvenement(event.build())
    }

    createAccesPageInterimEvenement(): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeTypeEvenement('ACCES_PAGE_INTERIM_PCO')
            .commentaire(
                `L'utilisateur ${connectedUser.idUtilisateurSi} a accédé à la page de gestion des intérimaires`,
            )
        return this.evenementService.createEvenement(event.build())
    }

    createClickAjouterLocataireContactEvenement(codeChantier: string): Observable<EvenementDto> {
        const connectedUser = this.userState.store.selectSnapshot(UserState.getConnectedUser)
        const event = new EventBuilder()
            .utilisateurId(connectedUser.id)
            .codeChantier(codeChantier)
            .codeApplication(ApplicationCodeEnum.ajoutLocatairesContacts)
            .codeTypeEvenement('CLIQUE_PORTL_AJT_LOCA_CONTACTS')
            .commentaire(
                `L'utilisateur ${connectedUser.idUtilisateurSi} a cliqué sur "Accéder au guide détaillé" dans le portlet "Ajouter les locataires dans les contacts du téléphone" depuis le chantier ${codeChantier}`,
            )
        return this.evenementService.createEvenement(event.build())
    }
}

class EventBuilder {
    private _codeTypeEvenement: CodeTypeEvenement
    private _date: Date
    private _utilisateurId: number
    private _commentaire: string
    private _codeRhSalarie: string
    private _matriculeInterimaire: string
    private _codeApplication: string
    private _codeChantier: string

    constructor() {
        this._date = new Date()
    }

    codeTypeEvenement(codeTypeEvenement: CodeTypeEvenement): EventBuilder {
        this._codeTypeEvenement = codeTypeEvenement
        return this
    }

    utilisateurId(utilisateurId: number): EventBuilder {
        this._utilisateurId = utilisateurId
        return this
    }

    commentaire(commentaire: string): EventBuilder {
        const now = new Date()
        this._commentaire = commentaire + `, le ${now.toLocaleDateString()} à ${now.toLocaleTimeString()}`
        return this
    }

    codeRhSalarie(codeRhSalarie: string): EventBuilder {
        this._codeRhSalarie = codeRhSalarie
        return this
    }

    matriculeInterimaire(matriculeInterimaire: string): EventBuilder {
        this._matriculeInterimaire = matriculeInterimaire
        return this
    }

    codeApplication(codeApplication: string): EventBuilder {
        this._codeApplication = codeApplication
        return this
    }

    codeChantier(codeChantier: string): EventBuilder {
        this._codeChantier = codeChantier
        return this
    }

    build(): EvenementDto {
        return {
            codeRhSalarie: this._codeRhSalarie,
            date: this._date,
            utilisateurId: this._utilisateurId,
            codeChantier: this._codeChantier,
            matriculeInterimaire: this._matriculeInterimaire,
            commentaire: this._commentaire,
            codeTypeEvenement: this._codeTypeEvenement,
            codeApplication: this._codeApplication,
        }
    }
}
