<p *ngIf="status === applicationStatusEnum.ko" class="text-center m-4">
    Nous ne recensons pas de dépenses pour votre chantier
</p>
<div *ngIf="status === applicationStatusEnum.ok">
    <div class="row mb-3">
        <div class="col-7">Dépenses totales chantier :</div>
        <div class="col-5 text-end fw-bold">{{ data.depensesTotales | currency: "EUR" }}</div>
    </div>
    <div class="row mb-4">
        <div class="col">Dépenses totales mensuelles :</div>
    </div>
    <div class="row">
        <div class="col">
            <canvas #chartCanvas id="chart" class="chart-container">{{ chart }}</canvas>
        </div>
    </div>
</div>
