import { inject, Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { User } from '../../models/user'
import { environment } from '../../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { DeviceEvenementDto } from './dto/device-evenement.dto'

@Injectable({ providedIn: 'root' })
export class UserHttpService {
    private readonly http = inject(HttpClient)
    majUserDernierChantier(codeChantier: string): Observable<User> {
        return this.http
            .put<User>(`${environment.baseUrl}/utilisateurs/majDernierChantier/${codeChantier}`, null)
            .pipe(map((connectedUser) => new User(connectedUser)))
    }

    getConnectedUser(deviceEvenementDto: DeviceEvenementDto): Observable<User> {
        return this.http
            .post<User>(`${environment.baseUrl}/utilisateurs/connected-user`, deviceEvenementDto)
            .pipe(map((connectedUser) => new User(connectedUser)))
    }
}
