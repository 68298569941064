import { inject, Injectable } from '@angular/core'
import { DocumentGedDto } from '../data-access/http/dto/document-ged.dto'
import { map, Observable, of, tap } from 'rxjs'
import { PieceJointeDto } from '../data-access/http/dto/piece-jointe.dto'
import { DOCUMENT } from '@angular/common'
import { PieceJointeDomainService } from './piece-jointe.domain'

@Injectable({ providedIn: 'root' })
export class DocumentationDomainService {
    private readonly document = inject(DOCUMENT)
    private readonly pieceJointeDomainService = inject(PieceJointeDomainService)

    // Selectors
    // Actions
    openDocument(documentGedDto: DocumentGedDto): Observable<[Blob, PieceJointeDto]> {
        if (documentGedDto.lienDocumentDrive) {
            return of(documentGedDto).pipe(
                tap((document) => this.document.defaultView.open(document.lienDocumentDrive)),
                map(() => [null, null]),
            )
        }
        return this.pieceJointeDomainService.downloadPieceJointeById(documentGedDto.document_id)
    }
}
