export enum ApplicationCodeEnum {
    pointageCompagnons = 'POINTAGE_COMPAGNONS',
    betonLeon = 'BETON_LEON',
    locmat = 'LOCMAT',
    donneesInterimaires = 'DONNEES_INTERIMAIRES',
    kairnial = 'KAIRNIAL',
    gestionDroits = 'GESTION_DROITS',
    donneesRh = 'DONNEES_RH',
    planningLivraisonChantier = 'PLANNING_LIVRAISON_CHANTIER',
    at360entites = 'AT360_ENTITES',
    at360chantier = 'AT360_CHANTIER',
    accueilChantier = 'ACCUEIL_CHANTIER',
    pointageChantier360 = 'POINTAGE_CHANTIER_360',
    mesIdees = 'MES_IDEES',
    infosSecurite = 'INFOS_SECURITE',
    formOutilsInfo = 'FORM_OUTILS_INFO',
    actionsBetonLeon = 'ACTIONS_BETON_LEON',
    taskAssignment = 'TASK_ASSIGNMENT',
    boosterBox = 'BOOSTER_BOX',
    catalogueServiceMateriel = 'CATALOGUE_SERVICE_MATERIEL',
    achats360 = 'ACHATS_360',
    wifi = 'WIFI',
    stac = 'STAC',
    auditsLogements = 'AUDITS_LOGEMENTS',
    rendezVousLocataires = 'RENDEZ_VOUS_LOCATAIRES',
    documentationChantier = 'DOCUMENTATION_CHANTIER',
    ajoutLocatairesContacts = 'AJOUT_LOCATAIRES_CONTACTS',
}
