import { FormGroup } from '@angular/forms'
import { ChartOptions } from 'chart.js/dist/types'
import { HttpResponse } from '@angular/common/http'
import { saveAs } from 'file-saver'
import { Tooltip } from 'bootstrap'

export function getUrlWithoutQueryParams(url: string): string {
    return url.split('?')[0]
}

export function toggleFormEnable(form: FormGroup): void {
    const enabled = form.enabled
    enabled ? form.disable() : form.enable()
    for (const controlsKey in form.controls) {
        enabled ? form.get(controlsKey).disable() : form.get(controlsKey).enable()
    }
}

export function keepOnlyDigits(value: string): string {
    if (!value) {
        return ''
    }
    return value.replace(/\D/g, '')
}

export function validateOnlyDigitsAndSize(event: KeyboardEvent, value: string, size: number): void {
    const allowedKeys = ['Backspace', 'Delete', 'Enter', 'ArrowLeft', 'ArrowRight']

    if (!(/\d/.test(event.key) || allowedKeys.includes(event.key) || event.ctrlKey || event.metaKey)) {
        event.preventDefault()
    }

    if (keepOnlyDigits(value).length >= size && !allowedKeys.includes(event.key) && !event.ctrlKey && !event.metaKey) {
        event.preventDefault()
    }
}

export function downloadPdfFile(httpResponse: HttpResponse<Blob>): void {
    const filenameWrap = httpResponse.headers.get('content-disposition').split('filename=')[1]
    const filename = filenameWrap.substring(1, filenameWrap.length - 1)
    saveAs(httpResponse.body, filename)
}

export function getGenericChartOptions(): ChartOptions {
    return {
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                titleFont: {
                    size: 0,
                },
            },
        },
        responsive: true,
        scales: {
            x: {
                ticks: {
                    padding: 10,
                    font: {
                        weight: 'bold',
                        size: 13,
                    },
                },
                border: {
                    display: true,
                    width: 2,
                    z: 999,
                    color: '#e31b18',
                },
                grid: {
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                stacked: true,
            },
            y: {
                ticks: {
                    display: false,
                },
                border: {
                    display: false,
                },
                grid: {
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                stacked: true,
            },
        },
    }
}

export function activateTooltipsForComponent(): void {
    const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    ;[...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl))
}

export function downloadFile(blob: Blob, fileName: string): void {
    const objectUrl = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = objectUrl
    a.download = fileName
    a.click()
    URL.revokeObjectURL(objectUrl)
}
