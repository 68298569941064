import { inject, Injectable } from '@angular/core'
import { GlobalState } from '../data-access/state/global.state'
import { Subject } from 'rxjs'

/**
 * This state is meant to manage inter-module communication
 * It acts as a globally accessible cache
 */
@Injectable({ providedIn: 'root' })
export class GlobalDomainService {
    private readonly globalState = inject(GlobalState)

    // Selectors
    stopAllLocalSpinners$ = new Subject<void>()
    displayGlobalSpinner$ = this.globalState.store.select(GlobalState.getShouldDisplayGlobalSpinner)

    shouldStartDocumentSpinner(): boolean {
        return (
            this.globalState.store.selectSnapshot(GlobalState.isSignatureTerminee) &&
            !!localStorage.getItem('enveloppeId')
        )
    }

    enveloppeId(): string {
        return localStorage.getItem('enveloppeId')
    }

    // Actions

    stopSpinner(): void {
        this.stopAllLocalSpinners$.next()
    }

    startGlobalSpinner(): void {
        this.globalState.store.update((state) => ({
            ...state,
            shouldDisplayGlobalSpinner: true,
        }))
    }

    stopGlobalSpinner(): void {
        this.globalState.store.update((state) => ({
            ...state,
            shouldDisplayGlobalSpinner: false,
        }))
    }

    setSignatureCompleted(isSignatureCompleted: boolean): void {
        this.globalState.store.update((state) => ({
            ...state,
            isSignatureCompleted,
        }))
    }

    setLastEnveloppeId(enveloppeId: string): void {
        localStorage.setItem('enveloppeId', enveloppeId)
    }

    resetSignature(): void {
        localStorage.removeItem('enveloppeId')
        this.globalState.store.update((state) => ({
            ...state,
            isSignatureCompleted: false,
        }))
    }
}
