import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NbHeureSemaine, PointageCompagnonsDto } from '../../../data-access/http/dto/pointage-compagnons-dto'
import { Chart, ChartOptions, TooltipItem } from 'chart.js/auto'
import dayjs from 'dayjs'
import * as weekOfYear from 'dayjs/plugin/weekOfYear'
import { getGenericChartOptions } from '../../../../shared/utils/utils'
import { AsyncPipe, NgClass, NgIf } from '@angular/common'
import { ChartData } from 'chart.js/dist/types'
import { newDateFromString } from '../../../../shared/utils/date.utils'

dayjs.extend(weekOfYear)

@Component({
    selector: 'app-pointage-compagnon-portlet-body',
    templateUrl: './pointage-compagnon-portlet-body.component.html',
    styleUrls: ['./pointage-compagnon-portlet-body.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, AsyncPipe],
})
export class PointageCompagnonPortletBodyComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('chart')
    chartCanvas: ElementRef

    @Input()
    data: PointageCompagnonsDto

    protected chart: Chart
    protected thereIsData: boolean = false
    private nbDataPoints: number = 8

    constructor(@Inject('DATA') private dataInjected: string) {}

    ngOnInit(): void {
        this.data = this.data || JSON.parse(this.dataInjected)
        this.data.datePointageEncours = newDateFromString(this.data.datePointageEncours)
        this.thereIsData = !!this.data.datePointageEncours
    }

    ngAfterViewInit(): void {
        if (this.thereIsData) {
            this.createChart()
        }
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.destroy()
        }
    }

    isDateBefore7DaysAgo(datePointageEncours: Date): boolean {
        const formattedDate = dayjs(datePointageEncours)
        const sevenDaysAgo = dayjs().subtract(7, 'day')
        return formattedDate?.isBefore(sevenDaysAgo, 'day')
    }

    isMonthDifferentFromToday(datePointageEncours: Date): boolean {
        const today = dayjs()
        const formattedDate = dayjs(datePointageEncours)
        return !(dayjs(today).isSame(formattedDate, 'month') && dayjs(today).isSame(formattedDate, 'year'))
    }

    isDateAfterYesterday(datePointageEncours: Date): boolean {
        const yesterday = dayjs().subtract(1, 'day')
        return !dayjs(datePointageEncours)?.isBefore(yesterday, 'day')
    }

    createChart(): void {
        this.chart = new Chart(this.chartCanvas.nativeElement.getContext('2d'), {
            type: 'bar',
            data: this.buildChartData(),
            options: this.buildChartOptions(),
        })
    }

    buildChartData(): ChartData {
        const weekLabels: string[] = []
        for (let i = 0; i < this.nbDataPoints; i++) {
            weekLabels.push(
                'S' +
                    dayjs()
                        .subtract(this.nbDataPoints - (i + 1), 'week')
                        .week(),
            )
        }

        const sortedData: NbHeureSemaine[] = []
        this.data.nbHeuresParSemaine
            .filter((value) => value.semaineNb <= 0 && value.semaineNb > -this.nbDataPoints)
            .forEach(
                (value) =>
                    /* eslint-disable @typescript-eslint/naming-convention */
                    sortedData.push({
                        ETT: value.ETT,
                        LG: value.LG,
                        semaineNb: value.semaineNb,
                    }),
                /* eslint-enable */
            )

        for (let i = 0; i < this.nbDataPoints; i++) {
            if (!sortedData.find((value) => value.semaineNb === -i)) {
                /* eslint-disable @typescript-eslint/naming-convention */
                sortedData.push({
                    ETT: -1,
                    LG: -1,
                    semaineNb: -i,
                })
                /* eslint-enable */
            }
        }

        sortedData.sort((a, b) => a.semaineNb - b.semaineNb)
        const dataEtt: number[] = sortedData.map((item) => item.ETT)
        const dataLg: number[] = sortedData.map((item) => item.LG)

        return {
            labels: weekLabels,
            datasets: [
                {
                    label: 'LG',
                    data: dataLg,
                    backgroundColor: '#ea9999',
                },
                {
                    label: 'ETT',
                    data: dataEtt,
                    backgroundColor: '#c9daf8',
                },
            ],
        }
    }

    buildChartOptions(): ChartOptions {
        const tooltipFooter = (tooltipItems: TooltipItem<'bar'>[]): string => {
            let sum = 0

            tooltipItems.forEach(function (tooltipItem: TooltipItem<'bar'>) {
                sum += tooltipItem.parsed.y
            })
            return '\nTotal: ' + sum.toFixed(2)
        }

        const chartOptions = getGenericChartOptions()
        chartOptions.plugins.tooltip.callbacks = {
            footer: tooltipFooter,
        }

        return chartOptions
    }
}
