import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { PieceJointeUploadDto } from './dto/piece-jointe-upload.dto'
import { PieceJointeS3Dto } from './dto/piece-jointe-s3.dto'
import { PhotoLocmatDto } from '../../../home/data-access/http/dto/photo-locmat.dto'
import { PieceJointeDto } from './dto/piece-jointe.dto'

@Injectable({
    providedIn: 'root',
})
export class PieceJointeHttpService {
    constructor(private http: HttpClient) {}

    getUploadUrl(pieceJointe: File): Observable<PieceJointeUploadDto> {
        // Utilisation bucket de DEV en local
        const lambdaBaseUrl = environment.baseUrl.includes('localhost')
            ? 'https://portailchantier-dev.leon-grosse.fr/portailchantier-api-rest'
            : environment.baseUrl

        const lambdaUploadPath =
            `${lambdaBaseUrl}/media/signed-url/generate/upload?filename=` +
            pieceJointe.name +
            `&application=` +
            `${environment.awsLambdaParam}`

        const formParams = new FormData()
        return this.http.post<PieceJointeUploadDto>(lambdaUploadPath, formParams)
    }

    uploadPieceJointeToS3(
        pieceJointe: File,
        jsonDataUploadResponse: PieceJointeUploadDto,
    ): Observable<PieceJointeS3Dto> {
        const formParams = new FormData()
        formParams.append('key', jsonDataUploadResponse.url.fields.key)
        formParams.append('x-amz-algorithm', jsonDataUploadResponse.url.fields['x-amz-algorithm'])
        formParams.append('x-amz-credential', jsonDataUploadResponse.url.fields['x-amz-credential'])
        formParams.append('x-amz-date', jsonDataUploadResponse.url.fields['x-amz-date'])
        formParams.append('x-amz-security-token', jsonDataUploadResponse.url.fields['x-amz-security-token'])
        formParams.append('policy', jsonDataUploadResponse.url.fields.policy)
        formParams.append('x-amz-signature', jsonDataUploadResponse.url.fields['x-amz-signature'])
        formParams.append('file', pieceJointe)

        const lambdaUploadS3Path = jsonDataUploadResponse.url.url
        const s3Response: PieceJointeS3Dto = { responseStatus: '' }
        return this.http.post<PieceJointeS3Dto>(lambdaUploadS3Path, formParams, { observe: 'response' }).pipe(
            map((response: HttpResponse<PieceJointeS3Dto>) => {
                s3Response.responseStatus = response.status.toString()
                return s3Response
            }),
        )
    }

    downloadPieceJointe(lambdaUrl: string): Observable<Blob> {
        return this.http.get(lambdaUrl, { responseType: 'blob' })
    }

    getPhotoLocmatByLambdaId(lambdaId: number): Observable<PhotoLocmatDto> {
        return this.http.get<PhotoLocmatDto>(environment.baseUrl + '/piecejointephotolocmat/download/' + lambdaId)
    }

    getPhotoById(lambdaId: string): Observable<PieceJointeDto> {
        return this.http.get<PieceJointeDto>(environment.baseUrl + '/piecejointe/download/' + lambdaId)
    }
}
