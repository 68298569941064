import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { ParamDto } from './dto/param-dto'
import { SaveParamDto } from './dto/save-param.dto'

@Injectable({
    providedIn: 'root',
})
export class ParamHttpService {
    private static readonly url = environment.baseUrl + '/param'
    private readonly http = inject(HttpClient)

    getParams(): Observable<ParamDto[]> {
        return this.http.get<ParamDto[]>(ParamHttpService.url + '/params')
    }
    saveParams(params: SaveParamDto[]): Observable<ParamDto[]> {
        return this.http.post<ParamDto[]>(ParamHttpService.url + '/params', params)
    }
}
