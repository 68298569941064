import { inject, Injectable } from '@angular/core'
import { OAuthService } from 'angular-oauth2-oidc'

@Injectable({
    providedIn: 'root',
})
export class AuthHttpService {
    private readonly oAuthService = inject(OAuthService)

    logOut(): void {
        /* eslint-disable @typescript-eslint/naming-convention */
        this.oAuthService.logOut({
            client_id: this.oAuthService.clientId,
            redirect_uri: this.oAuthService.redirectUri,
            response_type: 'code',
        })
        /* eslint-enable */
    }
}
