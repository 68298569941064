import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { keepOnlyDigits } from '../utils/utils'

export function carteBtpFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value == '' || control.value == null) {
            return null
        }

        const nbDigits = keepOnlyDigits(control.value).length
        const containsNonNumericalChars = nbDigits !== control.value.replaceAll(' ', '').length
        if (nbDigits < 8 || nbDigits > 10 || containsNonNumericalChars) {
            return { wrongFormat: { value: control.value } }
        } else {
            return null
        }
    }
}
