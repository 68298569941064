import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core'

import { environment } from './environments/environment'
import { AppComponent } from './app/app.component'
import { NgOptimizedImage } from '@angular/common'
import { OAuthModule } from 'angular-oauth2-oidc'
import { ToastrModule } from 'ngx-toastr'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { routes } from './app/app.routes'
import { provideAnimations } from '@angular/platform-browser/animations'
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser'
import { ErrorInterceptor } from './app/core/interceptors/error-interceptor'
import { AuthInterceptor } from './app/core/interceptors/auth-interceptor'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular'
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router'
import { DEVICE_INFO, getDeviceInfo } from './app/shared/factories/device-info.factory'

if (environment.production) {
    enableProdMode()
}

bootstrapApplication(AppComponent, {
    providers: [
        provideRouter(
            routes,
            withComponentInputBinding(),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
        ),
        importProvidersFrom(
            BrowserModule,
            NgbModule,
            EditorModule,
            ToastrModule.forRoot({
                timeOut: 5000,
                extendedTimeOut: 5000,
                progressBar: true,
                closeButton: true,
            }),
            OAuthModule.forRoot({
                resourceServer: {
                    allowedUrls: [environment.baseUrl],
                    sendAccessToken: false,
                },
            }),
            NgOptimizedImage,
        ),
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: DEVICE_INFO, useFactory: getDeviceInfo },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
    ],
}).catch((err) => console.error(err))
