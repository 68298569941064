<div *ngIf="!data?.length" class="text-center h-100 pt-4">Aucune donnée trouvée</div>
<table *ngIf="data?.length" class="table table-hover table-borderless">
    <thead class="border-bottom border-primary">
        <tr>
            <th class="portlet-table-cell lg-red">Type</th>
            <th class="portlet-table-cell lg-red" [style.width]="'500px'">Nom document</th>
            <th class="portlet-table-cell lg-red">Collaborateur</th>
            <th class="portlet-table-cell lg-red">Contributeur</th>
            <th class="portlet-table-cell lg-red">Date</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let document of data" (click)="openDocument(document.document_id)" role="button">
            <td class="text-truncate">
                <div class="max-height-2lh text-truncate" [title]="document.typeDocumentGed_nom">
                    {{ document.typeDocumentGed_nom }}
                </div>
            </td>
            <td [style.width]="'500px'">
                <div class="max-height-2lh text-truncate" [title]="document.nomDocument">
                    {{ document.nomDocument }}
                </div>
            </td>
            <td>
                <div class="max-height-2lh text-truncate" [title]="document.collaborateur">
                    {{ document.collaborateur }}
                </div>
            </td>
            <td>
                <div class="max-height-2lh text-truncate" [title]="document.contributeur">
                    {{ document.contributeur }}
                </div>
            </td>
            <td>
                <div class="max-height-2lh text-truncate">{{ document.createdDate | date: "dd/MM/yyyy" }}</div>
            </td>
        </tr>
    </tbody>
</table>
